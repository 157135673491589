import React from "react";
import SearchInput from "../../common/components/SearchInput";
import Config from "../../config";
import Ajax from "../../common/ajax";
import PaginationWithClick from "../../common/components/PaginationWithClick";
import Reference from "../components/Reference";
import Waiting from "../../common/containers/Waiting";
import queryString from "query-string";
import Row from "../../common/containers/Row";
import Col from "../../common/containers/Col";
import {withNamespaces} from "react-i18next";
import Totals from "./Totals";
import Input from "../../common/components/form/Input";
import Select from "../../common/components/form/Select";

class FastrefList extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            processing: false,
            references: [],
            showNoResults: false,
            pagination: {},
            types: [],
            lastSearchQuery: '',
            form: {
                query: queryString.parse(location.search).q || '',
                author: '',
                start_year: '',
                end_year: '',
                reference_type: '',
            },
        };

        this.state = {
            ...this.initialState,
        };

        this.fetchFastRef = this.fetchFastRef.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onTotalsClick = this.onTotalsClick.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    componentDidMount() {
        if (this.state.form.query) {
            this.fetchFastRef();
        }

        this.fetchReferenceTypes();
    }

    fetchReferenceTypes() {
        const url = `${Config.apiHost}references/types/`;
        Ajax.get(url).done(types => {
            this.setState(state => ({
                ...state,
                types: types,
            }));
        });
    }

    onChange(e) {
        e.persist();
        this.setState(state => ({
            ...state,
            showNoResults: false,
            form: {
                ...state.form,
                [e.target.name]: e.target.value,
            }
        }));
    }

    onClear() {
        this.setState(state => ({
            ...state,
            ...this.initialState,
            types: state.types,
        }));
    }

    fetchFastRef() {
        this.setState(state => ({
            ...state,
            processing: true,
        }));

        const url = `${Config.apiHost}fastref/?${this.getQuery()}`;
        Ajax.get(url).done((pagination) => {
            const {results} = pagination;
            this.setState(state => ({
                ...state,
                processing: false,
                pagination: pagination,
                references: results,
                showNoResults: !results || results.length === 0,
                lastSearchQuery: state.form.query,
            }), () => {
                window.scrollTo(0, 0);
            });
        });
    }

    getQuery() {
        const {
            query,
            author,
            start_year,
            end_year,
            reference_type,
        } = this.state.form;
        return `search=${query}&author=${author}&sy=${start_year}&ey=${end_year}&reference_type=${reference_type}`;
    }

    onTotalsClick(referenceType) {
        this.setState(state => ({
            ...state,
            form: {
                ...state.form,
                reference_type: referenceType,
            },
        }), this.fetchFastRef);
    }

    onNavigate(url) {
        this.setState(state => ({
            ...state,
            processing: true,
        }));
        Ajax.get(url).done((pagination) => {
            this.setState(state => ({
                ...state,
                processing: false,
                pagination: pagination,
                references: pagination.results,
            }), () => window.scrollTo(0, 0));
        });
    }

    renderAdvancedSearchForm() {
        const {i18n} = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col md={3}>
                        <Select
                            label={i18n.t('Tipo')}
                            options={this.state.types.map(e => ({
                                value: e.key,
                                label: e.label
                            }))}
                            name="reference_type"
                            value={this.state.form.reference_type}
                            onChange={this.onChange}
                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            label={i18n.t('Autor')}
                            name="author"
                            value={this.state.form.author}
                            onChange={this.onChange}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            label={i18n.t('Ano inicial')}
                            name="start_year"
                            value={this.state.form.start_year}
                            onChange={this.onChange}
                        />
                    </Col>
                    <Col md={2}>
                        <Input
                            label={i18n.t('Ano final')}
                            name="end_year"
                            value={this.state.form.end_year}
                            onChange={this.onChange}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderHeader() {
        const {i18n} = this.props;
        if (this.state.references.length === 0) {
            return (
                <div className="text-center" style={{
                    marginBottom: '30px',
                    marginTop: 'calc(25vh)'
                }}>
                    <h2>{i18n.t('Pesquise em mais de 5 milhões de referências')}</h2>
                </div>
            );
        }
    }

    render() {
        const {i18n} = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col md={8} mdOffset={2}>
                        {this.renderHeader()}
                        <div className="email-list-item">
                            <SearchInput
                                value={this.state.form.query}
                                handleSearch={this.fetchFastRef}
                                onClear={this.onClear}
                                focus={true}
                                onChange={query => this.setState(state => ({
                                    ...state,
                                    form: {
                                        ...state.form,
                                        query
                                    },
                                }))}
                                advancedSearchForm={this.renderAdvancedSearchForm()}
                            />
                        </div>
                    </Col>
                </Row>

                <br/>

                <Row>
                    <Col md={2}>
                    </Col>
                    <Col md={8}>
                        {this.state.processing && (
                            <Waiting isProcessing={this.state.processing} />
                        )}

                        {this.state.showNoResults && (
                            <div className="well-sm">
                                {i18n.t('Nenhum resultado encontrado')}
                            </div>
                        )}

                        {this.state.references.map(r => {
                            return (
                                <Row key={r.id}>
                                    <Col md={12}>
                                        <Reference
                                            reference={r}
                                            highlightTerm={this.state.form.query}
                                            key={`${r.id}-${this.state.lastSearchQuery}`}
                                        />
                                        <br/>
                                    </Col>
                                </Row>
                            );
                        })}

                        <PaginationWithClick
                            handleClick={this.onNavigate}
                            pagination={this.state.pagination}
                        />

                        {(this.state.showNoResults || this.state.references.length === 0) &&
                            <Totals onClick={this.onTotalsClick} />}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(FastrefList);