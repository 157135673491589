import React from "react";
import PropTypes from "prop-types";
import truncate from "underscore.string/truncate";

import "../../references/components/reference.list.css";
import CopyButton from "../../fastref/components/CopyButton";

import {withNamespaces} from "react-i18next";
import HtmlUtils from "../../common/htmlutils";

class Reference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAbstract: false,
        };
        this.toggleAbstract = this.toggleAbstract.bind(this);
        this.referenceDivRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.highlightTerm) {
            HtmlUtils.highlight(
                this.props.highlightTerm, this.referenceDivRef.current);
        }
    }

    toggleAbstract() {
        this.setState({
            ...this.state,
            showAbstract: !this.state.showAbstract,
        });
    }

    renderCopyButton() {
        return (
            <CopyButton reference={this.props.reference} />
        );
    }

    render() {
        const {i18n} = this.props;

        const r = this.props.reference;

        return (
            <div className="email-list-item">
                <div className="email-list-detail" ref={this.referenceDivRef}>
                    <a role="button" className="reference-title">
                        {`${r.title_highlighted || r.title_by_type}${r.subtitle? ': ' + r.subtitle : ''}`}.
                    </a>

                    {r.author_separated_by_colon &&
                    <span className="reference-author">
                            &nbsp;
                        {truncate(r.author_separated_by_colon, 70)}
                        </span>}

                    {(r.booktitle || r.journal || r.school) &&
                    <span className="text-info">
                            &nbsp;
                        &#8210;
                        &nbsp;
                        {r.booktitle || r.journal || r.school}
                        </span>}

                    {r.year &&
                    <span className="text-info">
                            &nbsp;
                        ({r.year})
                        </span>}

                    {r.formatted_url &&
                    <span>
                        &nbsp;
                        &#8210;
                        &nbsp;
                        <a target="_blank" rel="noopener" >{truncate(r.formatted_url, 50)}</a>
                        </span>}

                    <span className="text-muted">
                        &nbsp;
                        &#8210;
                        &nbsp;
                        {r.typename}
                    </span>

                    {r.abstract &&
                    <p className="msg">
                        {!this.state.showAbstract &&
                        <i>{truncate(r.abstract, 200)}</i>}

                        {this.state.showAbstract &&
                        <i>{r.abstract}</i>}

                        <a role="button" onClick={this.toggleAbstract}>
                            &nbsp;
                            {this.state.showAbstract ? i18n.t('menos') : i18n.t('mais')}
                        </a>
                    </p>}

                    <div className="msg" style={{marginTop: '8px'}}>
                        {this.renderCopyButton()}
                    </div>
                </div>
            </div>
        );
    }
}

Reference.propTypes = {
    reference: PropTypes.object.isRequired,
    highlightTerm: PropTypes.string,
};

export default withNamespaces()(Reference);