import React from "react";
import PropTypes from "prop-types"
import debounce from 'lodash.debounce';
import {withNamespaces} from "react-i18next";
import Button from "./Button";

class SearchInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: this.props.value,
            showAdvancedForm: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.toggleAdvancedForm = this.toggleAdvancedForm.bind(this);
        this.handleAdvancedClick = this.handleAdvancedClick.bind(this);
        this.debouncedHandleSearch = debounce(this.props.handleSearch, 500);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.focus) {
            this.inputRef.current.focus();
        }
    }

    handleInputChange(e) {
        e.persist();
        this.setState(state => ({
            ...state,
            searchTerm: e.target.value
        }), () => {
            if (this.props.searchOnType) {
                this.debouncedHandleSearch(this.state.searchTerm);
            }
        });

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    handleEnter(e) {
        if (e.key === "Enter") {
            if (e.shiftKey && this.props.onShiftEnter) {
                this.props.onShiftEnter(this.state.searchTerm);
            } else {
                this.props.handleSearch(this.state.searchTerm);
            }
            this.setState(state => ({
                ...state,
                showAdvancedForm: false,
            }));
        }
    }

    handleAdvancedClick() {
        this.props.handleSearch();
        this.setState(state => ({
            ...state,
            showAdvancedForm: false,
        }));
    }

    handleClearClick(e) {
        this.setState(state => ({
            ...state,
            searchTerm: ''
        }));

        if (this.props.onChange) {
            this.props.onChange(null);
        }

        if (this.props.onClear) {
            this.props.onClear();
        } else {
            this.props.handleSearch('');
        }
    }

    toggleAdvancedForm() {
        this.setState(state => ({
            ...state,
            showAdvancedForm: !this.state.showAdvancedForm,
        }));
    }

    render() {
        let { i18n, placeholder } = this.props;
        if (!placeholder) {
            placeholder = i18n.t('Buscar');
        }

        return (
            <React.Fragment>
                <div className="input-group input-search">
                    <input ref={this.inputRef}
                           type="text"
                           name="searchTerm"
                           autoFocus={this.props.focus}
                           value={this.state.searchTerm}
                           placeholder={placeholder}
                           className="form-control"
                           onChange={this.handleInputChange}
                           onKeyPress={this.handleEnter}/>
                    <span className="input-group-btn">
                        {this.props.advancedSearchForm && (
                            <button className="btn btn-default"
                                    title={i18n.t('Busca avançada')}
                                    onClick={this.toggleAdvancedForm}>
                                <i className={`icon mdi mdi-caret-${this.state.showAdvancedForm ? 'up' : 'down'}`}/>
                            </button>
                        )}
                        {this.state.searchTerm && this.props.showClear && (
                            <button className="btn btn-default"
                                    onClick={this.handleClearClick}>
                                <i className="icon mdi mdi-close"/>
                            </button>
                        )}
                        <button className="btn btn-default"
                                type="submit"
                                onClick={() => this.props.handleSearch(this.state.searchTerm)}>
                            <i className="icon mdi mdi-search"/>
                        </button>
                    </span>

                    {this.props.advancedSearchForm &&
                        <div className="panel panel-default" style={{
                            display: this.state.showAdvancedForm ? 'block' : 'none',
                            zIndex: '10000',
                            position: 'absolute',
                            marginTop: this.inputRef.current && (this.inputRef.current.clientHeight + 5),
                            width: '100%',
                        }}>
                            <div className="panel-heading">
                                <div className="tools">
                                    <span className="icon mdi mdi-close"
                                          onClick={this.toggleAdvancedForm}/>
                                </div>
                            </div>
                            <div className="panel-body" onKeyPress={this.handleEnter}>
                                {this.props.advancedSearchForm}
                                <br/>
                                <Button
                                    type="primary"
                                    onClick={this.handleAdvancedClick}
                                    size="lg"
                                >
                                    {i18n.t('Buscar')}
                                </Button>
                                {' '}
                                <Button
                                    type="default"
                                    onClick={() => {this.handleClearClick(); this.toggleAdvancedForm();}}
                                    size="lg"
                                >
                                    {i18n.t('Limpar')}
                                </Button>
                            </div>
                        </div>}
                </div>
            </React.Fragment>
        )
    }
}

SearchInput.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onShiftEnter: PropTypes.func,
    onClear: PropTypes.func,
    searchOnType: PropTypes.bool,
    showClear: PropTypes.bool,
    advancedSearchForm: PropTypes.element,
    focus: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
};

SearchInput.defaultProps = {
    showClear: true,
    value: '',
    handleSearch: function (term) {
        console.log("Handle search called:", term);
    },
};

export default withNamespaces()(SearchInput);