import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Config from "../../config";
import Ajax from "../../common/ajax";
import ButtonDropdown from "../../common/components/ButtonDropdown";
import Waiting from "../../common/containers/Waiting";
import {withNamespaces} from "react-i18next";
import {isLoggedIn} from "../../context/global";

class CopyButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folders: [],
            processing: false,
            copied: false,
        };
        this.fetchUserFolders = this.fetchUserFolders.bind(this);
        this.copyFastRefReference = this.copyFastRefReference.bind(this);
    }

    fetchUserFolders() {
        this.setState({
            ...this.state,
            processing: true,
        });
        const url = `${Config.apiHost}folders/`;
        Ajax.get(url).done(folders => {
            this.setState({
                ...this.state,
                folders,
                processing: false,
            });
        }).fail(() => {
            this.setState(state => ({...state, processing: false}));
        });
    }

    copyFastRefReference(folder) {
        if (!isLoggedIn()) {
            document.location = `${Config.djangoHost}accounts/login`;
            return;
        }

        this.setState({
            ...this.state,
            processing: true,
        });

        const url = `${Config.apiHost}fastref/${this.props.reference.id}/copy/`;
        const data = {};

        if (folder) {
            data.folder = folder.id;
        }

        Ajax.postJSON(url, data).done(reference => {
            this.setState({
                ...this.state,
                processing: false,
                copied: true,
            });
            this.props.onCopy && this.props.onCopy(reference);
        }).fail(error => {
            const {i18n} = this.props;
            alert(error.responseJSON ?
                error.responseJSON.detail : i18n.t('Erro inesperado'));
            this.setState({
                ...this.state,
                processing: false,
            });
        });
    }

    render() {
        const {i18n} = this.props;

        if (this.state.copied) {
            return (
                <small className="text-success">
                    <i className="icon mdi mdi-check"/> {i18n.t('Copiado')}
                </small>
            );
        } else {
            return (
                <ButtonDropdown
                    label={<span><i className="icon mdi mdi-copy"/> {i18n.t('Copiar para minha conta')}</span>}
                    onClick={this.fetchUserFolders}
                    size="xs"
                    processing={this.state.processing}
                    type="default">
                    <li>
                        <a role="button"
                           onClick={() => this.copyFastRefReference()}>
                            {i18n.t('Sem marcador')}
                        </a>
                    </li>
                    {this.state.folders && this.state.folders.length > 0 &&
                        <li className="dropdown-header">{i18n.t('Marcadores')}</li>}

                    <Waiting isProcessing={this.state.processing}/>
                    {this.state.folders.map(f => {
                        return (
                            <li key={f.id}>
                                <a role="button"
                                   onClick={() => this.copyFastRefReference(f)}>
                                    {f.name}
                                </a>
                            </li>
                        );
                    })}
                </ButtonDropdown>
            );
        }
    }
}

CopyButton.propTypes = {
    reference: PropTypes.object.isRequired,
    /**
     * Function that receives the reference created after the copy.
     */
    onCopy: PropTypes.func,
};

export default withNamespaces()(CopyButton);