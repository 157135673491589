import React from "react";
import PropTypes from "prop-types";

export default class ButtonDropdown extends React.Component {
    render() {
        const {
            dropUp,
            type,
            size,
            block,
            onClick,
            disabled,
            processing,
            label,
            active,
            width,
            maxHeight,
            children,
            ...rest
        } = this.props;

        const dropDownMenuStyle = {};

        if (maxHeight) {
            dropDownMenuStyle.height = 'auto';
            dropDownMenuStyle.maxHeight = maxHeight;
            dropDownMenuStyle.overflowX = 'hidden';
        }

        if (width) {
            dropDownMenuStyle.width = `${width} !important`;
        }

        return (
            <div className={`btn-group ${dropUp ? 'dropup' : ''} ${block? 'btn-block': ''}`}>
                <button
                    className={`btn btn-${type} btn-${size} dropdown-toggle ${block? 'btn-block': ''} ${active ? 'active' : ''}`}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={onClick}
                    disabled={disabled || processing}
                    {...rest}
                >
                    {label}
                    {' '}
                    {!processing && <span className="caret"/>}
                    {processing &&
                        <i className="fas fa-spinner fa-pulse fa-1x fa-fw"/>}
                </button>
                <ul className="dropdown-menu ff-scrollbar" style={{...dropDownMenuStyle}}>
                    {children}
                </ul>
            </div>
        );
    }
}

ButtonDropdown.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    onClick: PropTypes.func,
    processing: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    type: PropTypes.oneOf(['success', 'primary', 'info', 'warning', 'danger',
        'default', 'link']),
    block: PropTypes.bool,
    dropUp: PropTypes.bool,
    active: PropTypes.bool,
    maxHeight: PropTypes.string,
    width: PropTypes.string,
};

ButtonDropdown.defaultProps = {
    type: 'primary',
    processing: false,
    size: 'md',
    block: false,
};