import React, {Component} from 'react';
import Config from "../../config";
import Ajax from "../../common/ajax";
import Panel from "../../common/containers/Panel";
import PropTypes from "prop-types";

class Totals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totals: null,
            processing: false,
        };
    }

    componentDidMount() {
        this.fetchTotals();
    }

    fetchTotals() {
        this.setState(state => ({...state, processing: true}));
        const url = `${Config.apiHost}fastref/totals/`;
        Ajax.get(url).done(totals => {
            this.setState(state => ({
                ...state,
                totals: totals.filter(e => e.total > 0),
                processing: false,
            }));
        });
    }

    render() {
        if (!this.state.totals || this.state.totals.length === 0) {
            return null;
        }

        return (
            <Panel isProcessing={this.state.processing}>
                {this.state.totals.map((entry, idx) => {
                    return (
                        <span
                            key={idx}
                            className="badge"
                            style={{margin: '4px'}}
                            role="button"
                            onClick={() => this.props.onClick(entry.entry_name)}
                        >
                            {entry.label} ({Number(entry.total).toLocaleString()})
                        </span>
                    );
                })}
            </Panel>
        );
    }
}

Totals.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default Totals;