import React from 'react';
import Content from '../common/containers/Content';
import PublicPage from '../common/containers/PublicPage';
import FastrefList from './components/FastrefList';
import ErrorBoundary from '../common/containers/ErrorBoundary';
import { withNamespaces } from 'react-i18next';

class FastrefPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      references: [],
      pagination: {},
    };
  }

  render() {
    const { i18n } = this.props;
    return (
      <PublicPage title={i18n.t('Banco de referências')}>
        <Content>
          <ErrorBoundary>
            <FastrefList />
          </ErrorBoundary>
        </Content>
      </PublicPage>
    );
  }
}

export default withNamespaces()(FastrefPage);
